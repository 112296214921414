import React, { useState, useEffect } from 'react'
import { PortfolioH1, PortfolioText, MailSVG, FlexRows, PortfolioP3, FlexColumns } from '../PortfolioElements'
import {BlurredBackground, PasswordContainer, PasswordInputField, SubmitButton, RedArrow, CloseIcon } from './PasswordFormElements'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import sha256 from 'crypto-js/sha256';



function PasswordForm(props) {
    const sha256Password = "6BC37A7B1960842A30DE3F616E84315B8559580E00F96746253607B5ECF746FD"
    const [values, setValues] = useState({
        password: '',
        error: ''
    })

    const [errors, setErrors] = useState({})

    const handleChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const handleSubmit = e => {
        e.preventDefault()
        if (sha256(values.password).toString().toUpperCase() === sha256Password) {
            props.authenticate()
            values.error = 'wrong password'
            setValues({
                error: ''
            })
        }
        else {
            values.error = 'wrong password'
            setValues({
                error: 'wrong password'
            })
        }
    }

    
    return (
        <>
            <BlurredBackground className='PasswordFormBlurredBackgroun'>


            <PasswordContainer className='PasswordContainer'>
                <FlexColumns 
                    style={{width: "100%", height: "100%", marginLeft: "10em"}}
                >
                    <CloseIcon onClick={() => props.toggleLoginScreen(false)}></CloseIcon>
                    <PortfolioH1 style={{color: "#FBF7F3", marginTop: "4em", fontSize: "6em"} }>
                        Locked
                    </PortfolioH1>
                    <div style={{marginTop: "3em"}}></div>
                    <PortfolioP3 style={{color: "#FBF7F3"} }>
                        To get access, please write me at <b>anna@anvin.ch</b>
                    </PortfolioP3>
                    <div style={{marginTop: "4em"}}></div>
    
                    <FlexRows>
                        <form onSubmit={handleSubmit}>
                        <PasswordInputField 
                            type='password' 
                            name='password' 
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange}>
                        </PasswordInputField>
                        <SubmitButton type='submit' 
                            style={{width: "10em", height: "6em"}}
                            >
                            <PortfolioP3 style={{color: "#FBF7F3", marginleft: "10em"} }>
                                Enter
                            </PortfolioP3>
                            <RedArrow type='submit'></RedArrow>
                        </SubmitButton>
                        </form>
                    </FlexRows>
                    <PortfolioP3 style={{color: "#EF6161", width: "16em", textAlign: "center"} }>
                        {values.error}
                    </PortfolioP3>
                
                </FlexColumns>


            </PasswordContainer>
            </BlurredBackground>
        </>
        
    )
}

export default PasswordForm

import React from 'react'
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import FilmGrain from './../FilmGrain/FilmGrain';
import image1 from '../../images/photography/photography1.jpg'
import image2 from '../../images/photography/photography2.jpg'
import image3 from '../../images/photography/photography3.jpg'
import image4 from '../../images/photography/photography4.jpg'
import image5 from '../../images/photography/photography5.jpg'
import image6 from '../../images/photography/photography6.jpg'
import image7 from '../../images/photography/photography7.jpg'
import image8 from '../../images/photography/photography8.jpg'
import image9 from '../../images/photography/photography9.jpg'
import image10 from '../../images/photography/photography10.jpg'
import image11 from '../../images/photography/photography11.jpg'
import image12 from '../../images/photography/photography12.jpg'
import image13 from '../../images/photography/photography13.jpg'

import WebGL from './WebGL.js'
import firebase from './../../Firebase.js'
import 'firebase/storage';

import './Photography.css'
import { wait } from '@testing-library/dom';
import FirebaseDownloader from './../FirebaseDownloader.js';

const images = document.querySelectorAll('img:not([src*="https://tympanus.net/codrops/wp-content/banners/"])')
let imagesIndex = 0
    
let imageElements = []
let imageSources = []

Array.from(images).forEach(element => {
  const image = new Image()

  image.src = element.src
  image.onload = _ => {
    imagesIndex += 1

    if (imagesIndex === images.length) {
      document.documentElement.classList.remove('loading')
      document.documentElement.classList.add('loaded')
    }
  }
})

let counter = 0;
let canvasHolderRef = React.createRef();
let webglInitialized = false;

export const Photography = (props) => {

    // const [isLoaded, setLoaded] = useState(false);
    // const [imageSources, setImageSources] = useState([]);
    const [image, setImageSources] = useState("abc");

    useEffect(() => {
        console.log("Photography component rendered");
        // let canvas = ReactDOM.findDOMNode(myRef.current);
        // let ctx = canvas.getContext('2d');

        // ctx.fillStyle = 'rgb(200,0,0)';
        // ctx.fillRect(10, 10, 55, 50);

        let canvasHolder = ReactDOM.findDOMNode(canvasHolderRef.current);
        if (!webglInitialized) {
            new WebGL(canvasHolder);
            webglInitialized = true;
        }
      });
    // let firebaseDownloader = new FirebaseDownloader();
    // if (counter < 10) {
    //     firebaseDownloader.downloadImages().then(function(result) {setImageSources(result[0]); console.log(">>>>>>>>"); console.log(result[0])});
    //     counter += 1;
    // }

    // if (loading) return null

    // necessary because the body height is higher the main page 
    document.body.style.height = "100vh";

    return (
        <div>
            {/* <Header title='hello'/>  */}
            <div scroll="no" className="canvas-container">
                <div className="canvas-holder" ref={canvasHolderRef} />
                <div className="empty">
                    <div className="gallery">
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image1}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image2}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image3}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image4}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image5}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image6}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image7}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image8}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image9}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image10}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image11}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image12}/>
                        </figure>
                        <figure className="gallery-figure">
                            <img className="gallery-image" src={image13}/>
                        </figure>
                    </div>
                </div>
                {/* <div className="grain-layer"></div> */}
            </div>
            <FilmGrain/>
        </div>
    )
}



export default Photography
import firebase from "firebase/compat/app";


const firebaseConfig = {
    apiKey: "AIzaSyChsAZSmkfS4Dlda-VSkuYNdlcC5jYGyZM",
    authDomain: "annvin.firebaseapp.com",
    databaseURL: "https://annvin.firebaseio.com",
    projectId: "annvin",
    storageBucket: "annvin.appspot.com",
    messagingSenderId: "965042373189",
    appId: "1:965042373189:web:d13c2bfd7ab3fe1920992c"
  };

// export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
export default firebase.initializeApp(firebaseConfig);
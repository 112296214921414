import React from 'react'

import { Line, PortfolioH1, PortfolioContainer, FlexColumns, PortfolioP1, PortfolioText, SunSVG } from '../PortfolioElements'
import ProjectDescription from './ProjectDescription'
import gemscopeResearch from '../../images/uxgemscope.jpg';
import gemscopePrototyping from '../../images/uigemscope.jpg';
import project24cm from '../../images/pizzawein.jpg';
import titan from '../../images/TITAN.jpg';
import sunrise from '../../images/SunriseImage.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

const ProjectsSection = (props) => {
    return (
        <>
            <PortfolioContainer id='projects' className='PortfolioContainer' extended={true}>
            <PortfolioText className='AboutText'>
                <ScrollAnimation animateIn="fadeInUp">
                    <SunSVG style={{position: "absolute", marginTop: "18em"}}/>
                    <PortfolioH1 style={{textAlign: "right", paddingTop: "2em", paddingBottom: "4em"}}>
                        Selected Projects
                    </PortfolioH1>
                </ScrollAnimation>
                <ProjectDescription 
                    title="UX Design Internship at Sunrise" 
                    description=    ""
                    tags="2023 | UX Design "
                    image={sunrise}
                    link="https://www.figma.com/proto/XSqftkZZEi2bnzguaiIqni/Sunrise-Internship-projects?page-id=0%3A1&node-id=1%3A2&viewport=702%2C703%2C0.48&scaling=scale-down-width&starting-point-node-id=1%3A2"
                    followLink={props.followLink}
                    side="right"
                />                
                <ProjectDescription
                    title="An approach to using the capabilities of modern smartphones to popularize scientific communities"
                    description="A native app with a digital microscope and an open platform for people interested in mineralogy"
                    tags="2021 | UX Design "
                    image={gemscopeResearch}
                    link="https://www.figma.com/proto/YxjNp3gCErZejzILTKMlmI/Portfolio-2021?page-id=111%3A17&node-id=1245%3A549&viewport=501%2C48%2C0.09&scaling=min-zoom&starting-point-node-id=1245%3A549&show-proto-sidebar=1&hide-ui=1"
                    followLink={props.followLink}
                    side="left"
                />
                <ProjectDescription
                    title="Visual design and prototype of the Gemscope application" 
                    description="A native app with a digital microscope and an open platform for people interested in mineralogy"
                    tags="2021 | UI Design | Illustration"
                    image={gemscopePrototyping}
                    link="https://www.figma.com/proto/YxjNp3gCErZejzILTKMlmI/Presentation?page-id=111%3A17&node-id=1484%3A613&viewport=190%2C1104%2C0.07&scaling=scale-down-width&starting-point-node-id=1484%3A613&show-proto-sidebar=1"
                    followLink={props.followLink}
                    side="right"
                />
                <ProjectDescription 
                    title="Expanding opportunities to promote small businesses during a pandemic "
                    description="24cm is a food delivery app for a pizza and wine restaurant which aims to eliminate the ordering dilemma that arises from an overload of choices" 
                    tags="2021 | UX/UI Design | Illustration"
                    image={project24cm}
                    link="https://www.figma.com/proto/YxjNp3gCErZejzILTKMlmI/Portfolio-2021?page-id=0%3A1&node-id=1183%3A386&viewport=501%2C48%2C0.06&scaling=min-zoom&starting-point-node-id=1183%3A386&show-proto-sidebar=1&hide-ui=1"
                    followLink={props.followLink}
                    side="left"
                />                
            </PortfolioText>
            </PortfolioContainer>
        </>
    )
}

export default ProjectsSection

import React from 'react'
import { PortfolioH1, PortfolioContainer, PortfolioText, MailSVG, FlexRows } from '../PortfolioElements'
import ContactButton from './ContactButton'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

function Contact() {
    
    return (
        <>
            <PortfolioContainer id='contact' className='PortfolioContainer' extended='false' >
                <PortfolioText className='Text' id='contact'>
                    <ScrollAnimation animateIn="fadeInUp">
                        <PortfolioH1 style={{textAlign: "center", marginTop: "-1em"}}>
                            Feel free to say HEY
                        </PortfolioH1>
                        <MailSVG style={{position: "absolute", marginLeft: "85em", marginTop: "-8em"}}/>
                    </ScrollAnimation>
                    <FlexRows style={{marginTop: "10em", justifyContent: "space-around"}}>
                        <ScrollAnimation animateIn="fadeInUp" delay="200">
                            <ContactButton text="anna@anvin.ch" link="mailto:anna@anvin.ch"/>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp" delay="400">
                            <ContactButton text="LinkedIn" link="https://www.linkedin.com/in/anya-vinokurova/"/>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp" delay="600">
                            <ContactButton text="Instagram" link="https://www.instagram.com/an___vin/"/>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp" delay="800">
                            <ContactButton text="Behance" link="https://www.behance.net/annvin"/>
                        </ScrollAnimation>
                    </FlexRows>
                </PortfolioText>
            </PortfolioContainer>
        </>
        
    )
}

export default Contact

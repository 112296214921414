import styled from 'styled-components'
import { Link as LinkScroll } from 'react-scroll'

import arrow_down from '../../images/arrow_down.svg';

export const HeroContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
    z-index: 3;
    text-align: left;
`

export const HeroText = styled.div`
    display: flex;
    align-items: left;
    flex-direction: column;
    padding-bottom: 20vh;
    text-align: left;
    margin-top: 10em;
`

export const HeroMark = styled.mark`
    color: #EF6161;
    background: none;
`

export const HeroBottom = styled.div`
    display: flex;
    align-items: center;
    width: 100em;
    flex-direction: column;
    margin-top: -5em;
    /* padding-top: -10em; */
    /* position: absolute; */
    /* bottom: 10em; */
`

export const HeroLink = styled(LinkScroll)`
    cursor: pointer;
`

export const ArrowIcon = styled.div`
    height: 3em;
    width: 4em;
    padding-top: 6em;
    background-image: url(${ arrow_down});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
`

export const SocialLinkContainer = styled.div`
    position: absolute;
    align-self: flex-end;
    top: 60%;
    /* right: 10%; */
    height: 20em;
    width: 10em;
    z-index: 99;

    display: flex;
    align-items: center;
    flex-direction: column;
`

export const SocialIcon = styled.div`
    height: clamp(16px, 8em, 32px);
    width: clamp(16px, 8em, 32px);
    align-self: flex-end;
    background-repeat: no-repeat;
    background-position: center;
    /* background-position: right; */
    cursor: pointer;
`

export const SocialLink = styled.a`
    /* background-color: red; */
    /* height: 100%; */
    /* width: 100%; */
    /* height: 100vh; */
    /* width: 100vw; */
`

export const AvailableSVGContainer = styled.div`
    position: relative;
    margin-left: 6em;
    margin-top: -4.5em;
    width: 14em;
    /* min-width: min(1200px, 80vmin); */
    pointer-events: none;
`

export const AvailableSVG = styled.svg`
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    animation: dash 10s linear infinite;
    /* animation-delay: 10s; */
    animation-fill-mode: both;
    pointer-events: none;

    @keyframes dash {
        0% {
            stroke-dashoffset: 400;
        }
        26% {
            stroke-dashoffset: 400;
        }
        30% {
            stroke-dashoffset: 0;
        }
        96% {
            stroke-dashoffset: 0;
        }
        /* 100% {
            stroke-dashoffset: -400;
        } */
        100% {
            stroke-dashoffset: 400;
        }
        /* 2% {
            stroke-dashoffset: 350;
        }
        20% {
            stroke-dashoffset: 0;
        }
        25% {
            stroke-dashoffset: 0;
        }
        40% {
            stroke-dashoffset: -350;
        }
        50% {
            stroke-dashoffset: -350;
        }
        70% {
            stroke-dashoffset: 0;
        }
        80% {
            stroke-dashoffset: 0;
        } */
        /* 100% {
            stroke-dashoffset: 350;
        } */
        /* 0% {
            stroke-dashoffset: 350;
        }
        2% {
            stroke-dashoffset: 350;
        }
        60% {
            stroke-dashoffset: 0;
        }
        95% {
            stroke-dashoffset: 0;
        }
        100% {
            stroke-dashoffset: -350;
        } */
    }
`

export const HeroSVG = styled.svg`
    stroke-dasharray: 1200;
    /* stroke-dashoffset: 1200; */
    animation: dash 5s linear infinite;

    @keyframes dash {
        0% {
            stroke-dashoffset: 1200;
        }
        10% {
            stroke-dashoffset: 1200;
        }
        20% {
            stroke-dashoffset: 0;
        }
        90% {
            stroke-dashoffset: 0;
        }
        100% {
            stroke-dashoffset: -1200;
        }
    }
`

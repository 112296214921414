import styled from 'styled-components'

export const Carousel = styled.div`
    animation: carousel 40s linear;
    animation-iteration-count: infinite;

    @keyframes carousel {
        0% {
            margin-left: -200em;
        }
        50% {
            margin-left: -100em;
        }
        100% {
            margin-left: 0em;
        }
    }
`

import { Renderer, Camera, Transform, Plane } from 'ogl'
import NormalizeWheel from 'normalize-wheel'



import { lerp } from './math.js'

import Media from './Media.js'

class WebGL{
  constructor (canvasHolder) {

    this.canvasHolder = canvasHolder;
    
    this.scroll = {
      ease: 0.05,
      current: 0,
      target: 0,
      last: 0
    }

    this.speed = 2

    this.createGallery()
    this.createRenderer()
    this.createCamera()
    this.createScene()

    this.onResize()

    this.createGeometry()
    this.createMedias()

    this.update()

    this.addEventListeners()
  }

  createGallery () {
    // console.log("creating gallery");
    this.gallery = document.querySelector('.gallery')
    // console.log(this.gallery);
    // console.log("done.");
  }

  createRenderer () {
    console.debug("creating renderer");
    this.renderer = new Renderer({
      alpha: true
    })

    this.gl = this.renderer.gl

    // let canvasHolder = document.createElement("div");
    // canvasHolder.className = "canvas-holder";
    this.canvasHolder.appendChild(this.gl.canvas);

    // document.body.appendChild(this.canvasHolder);
    // document.body.appendChild(this.gl.canvas)
    // this.gallery = document.querySelector('.gallery').appendChild(this.gl.canvas)
    console.debug("canvas appended");
    console.debug("done.");
  }

  createCamera () {
    console.debug("creating camera");
    this.camera = new Camera(this.gl)
    this.camera.fov = 45
    this.camera.position.z = 5
    console.debug("done.");
  }

  createScene () {
    console.debug("creating scene");
    this.scene = new Transform()
    console.debug("done.");
  }

  createGeometry () {
    console.debug("creating geometry");
    this.planeGeometry = new Plane(this.gl, {
      heightSegments: 10
    })
    console.debug("done.");
  }

  createMedias () {
    console.debug("creating medias");

    this.mediaElements = document.querySelectorAll('.gallery-figure')
    // console.log(this.mediaElements);

    this.medias = Array.from(this.mediaElements).map(element => {
      let media = new Media({
        element,
        geometry: this.planeGeometry,
        gl: this.gl,
        height: this.galleryHeight,
        scene: this.scene,
        screen: this.screen,
        viewport: this.viewport
      })

      return media
    })
    console.log("done.");
  }

  /**
   * Events.
   */
  onTouchDown (event) {
    this.isDown = true

    this.scroll.position = this.scroll.current
    this.start = event.touches ? event.touches[0].clientY : event.clientY
  }

  onTouchMove (event) {
    if (!this.isDown) return

    const y = event.touches ? event.touches[0].clientY : event.clientY
    const distance = (this.start - y) * 2

    this.scroll.target = this.scroll.position + distance
  }

  onTouchUp (event) {
    this.isDown = false
  }

  onWheel (event) {
    const normalized = NormalizeWheel(event)
    const speed = normalized.pixelY

    this.scroll.target += speed * 0.5
  }

  /**
   * Resize.
   */
  onResize () {
    console.debug("onResize");
    this.screen = {
      height: window.innerHeight,
      width: window.innerWidth
    }

    this.renderer.setSize(this.screen.width, this.screen.height)

    this.camera.perspective({
      aspect: this.gl.canvas.width / this.gl.canvas.height
    })

    const fov = this.camera.fov * (Math.PI / 180)
    const height = 2 * Math.tan(fov / 2) * this.camera.position.z
    const width = height * this.camera.aspect

    this.viewport = {
      height,
      width
    }

    this.galleryBounds = this.gallery.getBoundingClientRect()
    this.galleryHeight = this.viewport.height * this.galleryBounds.height / this.screen.height

    if (this.medias) {
      this.medias.forEach(media => media.onResize({
        height: this.galleryHeight,
        screen: this.screen,
        viewport: this.viewport
      }))
    }
    console.debug("done");
  }

  /**
   * Update.
   */
  update () {
    this.scroll.target += this.speed

    this.scroll.current = lerp(this.scroll.current, this.scroll.target, this.scroll.ease)

    if (this.scroll.current > this.scroll.last) {
      this.direction = 'down'
      this.speed = 2
    } else if (this.scroll.current < this.scroll.last) {
      this.direction = 'up'
      this.speed = -2
    }

    if (this.medias) {
      this.medias.forEach(media => media.update(this.scroll, this.direction))
    }

      this.renderer.render({
      scene: this.scene,
      camera: this.camera
    })

    this.scroll.last = this.scroll.current

      window.requestAnimationFrame(this.update.bind(this))
  }

  /**
   * Listeners.
   */
  addEventListeners () {
    window.addEventListener('resize', this.onResize.bind(this))

    window.addEventListener('mousewheel', this.onWheel.bind(this))
    window.addEventListener('wheel', this.onWheel.bind(this))

    window.addEventListener('mousedown', this.onTouchDown.bind(this))
    window.addEventListener('mousemove', this.onTouchMove.bind(this))
    window.addEventListener('mouseup', this.onTouchUp.bind(this))

    window.addEventListener('touchstart', this.onTouchDown.bind(this))
    window.addEventListener('touchmove', this.onTouchMove.bind(this))
    window.addEventListener('touchend', this.onTouchUp.bind(this))
  }
}

export default WebGL
import React from 'react'

import { HeroBottom, HeroMark, ArrowIcon, SocialLinkContainer, SocialLink, SocialIcon, HeroSVG, AvailableSVG, AvailableSVGContainer } from './HeroSectionElements.js'
import { PortfolioContainer, PortfolioH1, PortfolioP1, PortfolioP3, PortfolioContent, PortfolioText, PortfolioP2} from '../PortfolioElements'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Scroll from 'react-scroll'

import arrow_down from '../../images/arrow_down.svg';
import linkedinImage from '../../images/Linkedin.svg';
import instagramImage from '../../images/Instagram.svg';
import behanceImage from '../../images/Behance.svg';

function HeroSection() {

    var scroll = Scroll.animateScroll;
    function handleClick() {
        console.log("click");
        alert("hi");
    }

    return (
        <>
            <PortfolioContainer className='PortfolioContainer' extended={false}>
                <div style={{marginTop: "30em"}}></div>
                <PortfolioText className='HeroText' style={{marginTop: "-20em"}}>

                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutUp">
                    <PortfolioH1>
                        I  am  <HeroMark>Anna</HeroMark>, 
                    </PortfolioH1>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" delay="400">
                    <PortfolioH1>
                        User  Experience  Designer
                    </PortfolioH1>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutUp" delay="700">
                    <PortfolioH1>
                        based  in  Zurich,  Switzerland
                    </PortfolioH1>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutUp" delay="900">
                    <PortfolioP1 style={{marginTop: "3em", whiteSpace: "nowrap"}}>
                            I am available for projects <b onClick={()=> window.open("mailto:anna@anvin.ch")} 
                                style={{cursor: 'pointer'}}>
                            contact me
                            </b>.
                    </PortfolioP1>
                    <AvailableSVGContainer>
                        <AvailableSVG 
                            width="100%" height="100%" viewBox="0 0 104 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 1C5 14.1932 21.3585 17.9691 34.1117 21.0153C46.6255 24.0043 59.1454 25.1141 71.8978 25.2456C81.5869 25.3455 89.3902 26.2419 99 24.5C99.7935 24.3562 103 24 103 22.5C103 21 100.821 19.8688 99.4192 19.2279C96.0977 17.7096 92.5592 16.7534 89.0523 16.0403C76.0164 13.3892 62.5551 13.3919 49.3744 14.1932C43.2063 14.5682 37.0551 15.2703 30.9288 16.1743C26.7755 16.7872 22.5258 17.3796 18.4655 18.632C17.9319 18.7966 14.0748 19.8212 14.2983 21.2536C14.6119 23.2637 19.7691 24.6222 20.9198 25.0072C29.7555 27.9635 39.0861 28.4338 48.2239 28.3885C53.7156 28.3613 59.2077 28.0923 64.6371 27.0628C67.6485 26.4918 71.1084 25.8034 73.8791 24.1731C76.3731 22.7057 72.1684 21.5502 71.3865 21.3728C68.0287 20.6107 64.5521 20.5603 61.1474 20.494C55.1428 20.377 49.1226 20.4999 43.1364 21.1047C28.0383 22.63 22.6113 23.6885 8 28.3885" stroke="#EF6161" strokeLinecap="round"/>
                        </AvailableSVG>
                    </AvailableSVGContainer>
                    </ScrollAnimation>
                    
                </PortfolioText>

                <HeroBottom className='HeroBottom'>
                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutUp" delay="1000" offset="0">
                    <PortfolioP3>
                        Scroll to learn more about
                        {"\n"}
                        me & projects
                    </PortfolioP3>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutUp" delay="1050" offset="0">
                        <ArrowIcon image={arrow_down} 
                        onClick={() => scroll.scrollMore(800, {delay: 0})}/>
                    </ScrollAnimation>
                </HeroBottom>

             </PortfolioContainer>
        </>
    )
}

export default HeroSection

import React, { useState, useEffect, useRef } from 'react'

import { SunSVG, PortfolioText, PortfolioH1, FlexRows, PortfolioContainer, PortfolioP1, PortfolioP2, PortfolioP3 } from '../PortfolioElements'
import { AboutPortrait, AboutSlogan, AboutSloganMark, AboutContainer, HeyEmojii, LearnMoreSection, DesignProcess, LearnMoreButton, Skills, Tech, LearnMoreArrow, Sculpture } from './AboutSectionElements.js'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Scroll from 'react-scroll'

function AboutSection({ isVisible }) {
    const [show, setShow] = React.useState();
    var scroll = Scroll.animateScroll;
    var Element = Scroll.Element;
    const learnMoreRef = useRef(null);

    return (
        <>
            <PortfolioContainer id='about' className='PortfolioContainer' extended={show} >
                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutUp">
                    <div style={{ marginTop: "5em" }}></div>
                    <PortfolioText className='AboutText'>
                        <SunSVG style={{ position: "absolute", marginLeft: "90em", marginTop: "8em" }} />
                        <PortfolioH1>
                            Some words about me
                        </PortfolioH1>
                    </PortfolioText>
                </ScrollAnimation>
                {/* <div style={{marginTop: "10em"}}></div> */}
                <FlexRows style={{marginTop: "-10em"}}>
                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutLeft">
                        <HeyEmojii style={{marginLeft: "-1em", marginTop: "2em"}} />
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutLeft">
                    <PortfolioP1 style={{marginLeft: "0em", width: "25em", textAlign: "left" }}>
                        Hi! I'm a User Experience Designer with a passion for aesthetic and functional interface design.
                    </PortfolioP1>
                    </ScrollAnimation>
                </FlexRows>
                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutLeft">
                    <AboutSlogan style={{textAlign: "left", marginTop: "0em"}}>
                    I believe that design is a <AboutSloganMark>unifying</AboutSloganMark> tool 
                    <br></br>
                    between <AboutSloganMark>human</AboutSloganMark> and <AboutSloganMark>product</AboutSloganMark> 
                    <br></br>
                    to create whole new emotions 
                    <br></br>
                    and experiences.
                    </AboutSlogan>
                </ScrollAnimation>
                {/* <div style={{ marginTop: "10em" }}></div> */}
                <AboutContainer style={{marginTop: "-10em"}}>
                    {/* <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutLeft">
                        <HeyEmojii />
                    </ScrollAnimation> */}
                    {/* <PortfolioP2 style={{minWidth: "20em"}}> */}
                    <ScrollAnimation animateIn="fadeInUp">
                        <PortfolioP2 style={{textAlign: "left", marginTop: "5em"}}>
                        I was born and raised in Yakutsk, the coldest city in the world. Being surrounded by the rich Yakut culture, I obtained a unique perspective on the world. 
                        <br></br>
                        <br></br>
                        My creative journey began in 2012 in St. Petersburg, Russia at the university of technology and design. I have been passionate about creating design ever since. 
                        <br></br>
                        <br></br>
                        I strive for personal and professional development. My inspiration usually comes from everything that surrounds me. Having a design degree has excited me to constantly look for new ideas and the right tools to implement them. 
                    </PortfolioP2>
                    </ScrollAnimation>
                    {/* <ScrollAnimation animateIn="fadeIn" style={{maxWidth: "65em"}}> */}
                    <ScrollAnimation animateIn="fadeInUp">
                        <AboutPortrait />
                        <Sculpture />
                    </ScrollAnimation>
                </AboutContainer>
                <ScrollAnimation animateIn="fadeInUp">
                    {/* <LearnMoreButton onClick={() => {setShow(!show); learnMoreRef.current.scrollIntoView()}}  > */}
                    <LearnMoreButton onClick={() => {
                        setShow(!show);
                        // scroll.scrollTo('learnMore', {smooth: true, containerId: 'learnMore'})
                        show ? scroll.scrollMore(-300, { delay: 0 }) : scroll.scrollMore(500, { delay: 0 });
                    }}  >
                        <FlexRows style={{ alignItems: "center", marginTop: "-10em", marginLeft: "53em"}}>
                            <PortfolioP3>Learn more</PortfolioP3>
                            <LearnMoreArrow isVisible={show} />
                        </FlexRows>
                    </LearnMoreButton>
                </ScrollAnimation>
                <div style={{ height: "20em" }}></div>

                <LearnMoreSection ref={learnMoreRef} isVisible={show}>
                    <ScrollAnimation animateIn="fadeInUp">
                        <Element id='learnMore' name="learnMore"></Element>
                        <FlexRows>
                            <Skills />
                            <Tech />
                        </FlexRows>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp">
                        <PortfolioH1 style={{ textAlign: "right", paddingTop: "2em" }}>
                            Design Process
                        </PortfolioH1>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp">
                        <DesignProcess />
                    </ScrollAnimation>
                </LearnMoreSection>
            </PortfolioContainer>
        </>

    )
}

export default AboutSection

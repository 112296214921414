import React from 'react'
import { FlexRows, PortfolioH2 } from '../PortfolioElements'
import { Carousel } from './CarouselTextElements'

function CarouselText(props) {
    var i = 1;
    return (
        <>
            <Carousel style={{animationDirection: props.direction}}>
                <FlexRows style={{marginLeft: props.marginLeft }}>
                    {[...Array(100)].map(() => (
                            <PortfolioH2 key={i++} onClick={()=> window.open(props.link)}
                                style={{marginRight: "2em", cursor: "pointer", 
                                }}>
                                {props.text}
                            </PortfolioH2>
                        ))
                    }
                </FlexRows>
            </Carousel>
        </>
    )
}

export default CarouselText

import styled from 'styled-components'
import portrait from '../../images/portrait.png';
import heyEmojii from '../../images/EmojiJEY.svg';
import designProcess from '../../images/design_process.svg';
import skills from '../../images/skills.svg';
import tech from '../../images/tech.svg';
import sculpture from '../../images/sculpt.png';

import redArrow from '../../images/REDarrowABOUT.svg';

export const AboutContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: flex-start;
`

/* export const AboutPortrait = styled(ScrollAnimation)` */
export const AboutPortrait = styled.div`
    width: 50em;
    height: 24em;
    margin-left: 6em;
    /* background-color: red; */
    background-image: url(${ portrait });
    /* padding-top: 20px; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
`

export const LearnMoreArrow = styled.div`
    height: 7em;
    width: 7em;
    padding-left: 3em;
    background-image: url(${ redArrow });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    
    transform: ${({ isVisible }) => (isVisible ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: all 0.3s ease-in-out;
`

export const HeyEmojii = styled.div`
    height: 7em;
    width: 7em;
    background-image: url(${ heyEmojii });
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    /* transform: rotate(-20deg); */
`

export const Sculpture = styled.div`
    position: absolute;
    height: 54em;
    width: 44em;
    top: -16em;
    left: 35em;
    background-image: url(${ sculpture });
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
`

export const DesignProcess = styled.div`
    position: relative;
    height: 70em;
    width: 120em;
    padding-top: 3em;
    left: -10em;
    background-image: url(${ designProcess });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

export const Tech = styled.div`
    height: 20em;
    width: 50em;
    margin-right: 5em;
    padding-top: 3em;
    background-image: url(${ skills });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

export const Skills = styled.div`
    height: 20em;
    width: 50em;
    padding-top: 3em;
    background-image: url(${ tech });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

export const LearnMoreButton = styled.div`
    width: 100em;
    height: 20em;
    cursor: pointer;
`

export const LearnMoreSection = styled.div`
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    margin-top: ${({ isVisible }) => (isVisible ? '0vh' : '-10em')};
    opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
    max-height: ${({ isVisible }) => (isVisible ? 'auto' : '0vh')};
    
    transition: opacity 0.5s ease-in-out;
`

export const AboutSlogan = styled.p`
    color: #151C1E;
    opacity: 0.5;
    font-family: Montserrat;
    font-size: 4.8em;
    text-align: left;
    white-space: pre-wrap;
    font-weight: 300;
`

export const AboutSloganMark = styled.mark`
    font-weight: 800;
    opacity: 0.25;
    background: none;
`

import styled from 'styled-components'

export const HomeOuterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background: #FBF7F3;
    overflow-x: hidden;
`

export const HomeInnerContainer = styled.div`
    width: 100em;
    /* width: 80vw; */
    /* max-width: 1200px; */
    background: #FBF7F3;
`
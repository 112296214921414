import styled from 'styled-components'
import star from '../../images/STAR.svg';

export const ProjectImageContainer = styled.div`
    width: 46em;
    height: 30em;
    margin-left: 1em;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
`

export const ProjectImage = styled.div`
    width: 93%;
    height: 93%;
    margin-left: 4%;
    margin-top: 2%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
`

export const Border = styled.div`
    height: 10em;
    width: 50em;
    /* padding-top: 3em; */
    background-image: url(${ star });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

export const ContactButtonContainer = styled.div`
    height: 3em;
    width: 19em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: 1px;
    border-color: black;
    border-style: solid;
    border-radius: 2em;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: black;
    }
`

export const Star = styled.div`
    position: absolute;
    height: 2em;
    width: 2em;
    margin-top: 0.4em;
    background-image: url(${ star });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

import React from 'react'

import { Line, PortfolioH1, PortfolioP1, PortfolioP2, PortfolioText, SunSVG } from '../PortfolioElements'
import CarouselText from './CarouselText';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

const CreativeSection = () => {
    return (
        <>
            <PortfolioText className='AboutText' id='projects'>
                <div style={{marginTop: "20em"}}/>
                <ScrollAnimation animateIn="fadeInUp" offset="100">
                <div>
                    <SunSVG style={{position: "absolute", marginLeft:"90em", marginTop: "11em"}}/>
                    <PortfolioH1 style={{textAlign: "left", paddingTop: "2em", paddingBottom: "1em"}} id='creative'>
                        Personal Projects
                    </PortfolioH1>
                </div>
                </ScrollAnimation>

                <ScrollAnimation animateIn="fadeInUp" offset="100">
                    <PortfolioP2 style={{paddingBottom: "4em", textAlign: "left"}}>
                        While my focus lies in UX/UI design, I love to work interdisciplinary to provide the best possible solution.
                    </PortfolioP2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" offset="100">
                    <CarouselText text="Branding        Illustration       Product Design       Rendering" link="https://www.behance.net/annvin" marginLeft="-45em" direction="reverse"/>
                    <Line style={{marginBottom: "-4em"}}/>
                    <CarouselText text="Photography" link="/photography" marginLeft="-50em" direction="normal"/>
                </ScrollAnimation>
                <div style={{marginTop: "20em"}}/> 
            </PortfolioText>
            <div style={{marginTop: "30em"}}/>
        </>
    )
}

export default CreativeSection

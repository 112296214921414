import React from 'react'

import { FlexRows, FlexColumns, PortfolioH2, PortfolioP1, PortfolioP2, PortfolioText, PortfolioP3 } from '../PortfolioElements'
import { ProjectArrow, ProjectImg, ProjectImageContainer } from './ProjectElements'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import { render } from '@testing-library/react';


function ProjectDescription(props) {
    return (
        <>
            <ScrollAnimation animateIn="fadeInUp" offset="100">
                <FlexRows>
                    <ProjectImageLeft {...props} />
                    <FlexColumns style={{maxWidth: "47em", height: "30em", marginLeft: "3em", marginRight: "0em", justifyContent: "center", overflowX: "hidden"}}>
                        <PortfolioP1 style={{width: "100%", marginBottom: "-1em", cursor: "pointer", textAlign: props.side}} 
                            onClick={()=> props.followLink(props.link)}>
                            { props.title }
                        </PortfolioP1>
                        <ProjectArrow/>
                        <PortfolioP2 style={{width: "100%", maxWidth: "40em", marginTop: "-2em", textAlign: props.side}}>
                            { props.description } 
                        </PortfolioP2>
                        <PortfolioP1 style={{width: "100%", maxWidth: "40em", marginTop: "-0em", textAlign: props.side}}>
                            { props.tags }
                        </PortfolioP1>
                    </FlexColumns>
                    <ProjectImageRight {...props} />
                </FlexRows>
                <div style={{height: "20em"}}/>
            </ScrollAnimation>
        </>
    )
}

function ProjectImageLeft(props){
    if(props.side == "left") {
        return (
            <ProjectImageContainer onClick={()=> props.followLink(props.link)}>
                <ProjectImg style={{backgroundImage: `url(${props.image})`}} />
            </ProjectImageContainer>
        )
    }
    else {
        return (
            <></>
        )
    }
}

function ProjectImageRight(props){
    if(props.side == "right") {
        return (
            <>
            <div style={{width: "3em", height: "100%"}}></div>
            <ProjectImageContainer onClick={()=> props.followLink(props.link)}>
                <ProjectImg style={{backgroundImage: `url(${props.image})`}} />
            </ProjectImageContainer>
            </>
        )
    }
    else {
        return (
            <></>
        )
    }
}

export default ProjectDescription

import React, {useState, useEffect} from 'react'
import { useInView } from "react-intersection-observer";
import AboutSection from '../components/AboutSection/AboutSection'
import HeroSection from '../components/HeroSection/HeroSection'
import Navbar from '../components/Navbar/Navbar'
import ProjectsSection from '../components/ProjectsSection/ProjectsSection'
import CreativeSection from '../components/CreativeSection/CreativeSection'
import Sidebar from '../components/Sidebar/Sidebar'
import Footer from '../components/Footer/Footer'
import { HomeOuterContainer, HomeInnerContainer } from './HomeElements'
import Contact from '../components/Contact/Contact'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import PasswordForm from '../components/PasswordForm/PasswordForm';
import { useCookies } from 'react-cookie';
import { withCookies } from "react-cookie";
import { FaAmericanSignLanguageInterpreting } from 'react-icons/fa';


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
  };
 
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
}


const Home = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [showLoginScreen, toggleLoginScreen] = useState(false)
    const [heroRef, heroSectionInView] = useInView({ threshold: 1 });
    const { width, height} = useWindowDimensions();
    const [lastSelectedLink, setLastSelectedLink] = useState('anvin.ch')
    const [cookies, setCookie] = useCookies(['authenticated']);

    
    const [aboutRef, aboutSectionInView] = useInView({ threshold: 0.5 , rootMargin: "-00%"});
    const [projectsRef, projectsSectionInView] = useInView({ threshold: 0.5 });
    const [creativeRef, creativeSectionInView] = useInView({ threshold: 0.5 });
    const [contactRef, conctactSectionInView] = useInView({ threshold: 0.5 });

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const followLinkIfAuthenticated = (link) => {

        let hasCookie = document.cookie.replace(/(?:(?:^|.*;\s*)authenticated\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        if(isAuthenticated) {
            window.open(link)
        }
        else if(hasCookie == 'true') {
            setIsAuthenticated(true);
            window.open(link)
        }
        else {
            // alert("not authenticated")
            toggleLoginScreen(true)
            setLastSelectedLink(link)
            // show Locked popul
        }
    };

    const authenticate = () => {
        setIsAuthenticated(true)
        toggleLoginScreen(false)
        setCookie('authenticated', 'true', {path: '/'});
        window.open(lastSelectedLink)
    }

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
        <div style={{visibility: showLoginScreen ? "visible" : "hidden"}}>
            <PasswordForm authenticate={authenticate} toggleLoginScreen={toggleLoginScreen}/>
        </div>
        <Navbar toggle={toggle} 
            sectionsInView={[heroSectionInView, aboutSectionInView, projectsSectionInView, creativeSectionInView, conctactSectionInView]}/>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HomeOuterContainer className="OuterContainer">
            <HomeInnerContainer className="InnerContainer">
                <section ref={heroRef}>
                    <HeroSection id='hero'/>
                </section>
                <div style={{marginTop: "10em"}}/>
                <section ref={aboutRef}>
                    <AboutSection id='about'/>
                </section>
                <div style={{marginTop: "10em"}}/>
                {/* <div ref={projectsRef} style={{height: "100em"}}/> */}
                <section ref={projectsRef} 
                // style={{maxHeight: "200em", width: "100em"}} 
                >
                    <ProjectsSection id='projects' followLink={followLinkIfAuthenticated}/>
                </section>
                <div style={{height: "10em"}}/>
                <section ref={creativeRef}>
                    <CreativeSection id='creative'/>
                </section>
                {/* <div style={{marginTop: "50em"}}/> */}
                <div style={{height: "40em"}}/>
                <section ref={contactRef}>
                    <Contact id='contact' />
                </section>
                <div style={{height: "30em"}}/>
                <Footer/>
            </HomeInnerContainer>
        </HomeOuterContainer>
        </>
    )
}

export default Home

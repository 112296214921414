import React from 'react'
import { Line, PortfolioP3 } from '../PortfolioElements'

function Footer() {
    
    return (
        <>
            <Line/>
            <PortfolioP3 style={{textAlign: "right", marginTop: "-1em"}}>
                Anna Vinokurova @ 2022
            </PortfolioP3>

        </>
        
    )
}

export default Footer

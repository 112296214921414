import styled from 'styled-components'
import projectArrow from '../../images/line.svg';

export const ProjectImageContainer = styled.div`
    width: 46em;
    height: 30em;
    margin-left: 1em;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    overflow-x: hidden;
    overflow-y: hidden;
`

export const ProjectImg = styled.div`
    width: 93%;
    height: 93%;
    margin-left: 4%;
    margin-top: 2%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    /* image-rendering: pixelated; */
    image-rendering: -webkit-optimize-contrast;
    
    &:hover{
        margin: 0;
        margin-top: -2%;
        width: 105%;
        height: 105%;
        
    }
`

export const ProjectArrow = styled.div`
    height: 10em;
    width: 50em;
    /* padding-top: 3em; */
    background-image: url(${ projectArrow});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

import React, { useState, useEffect } from 'react'
import { FaBars } from 'react-icons/fa'
import {Nav, NavbarContainer, LogoText, MobileIcon, NavMenu, NavSVGContainer, NavSVG,
    NavItem, NavLinks, LogoUpperPart, LogoLowerPart, AnvinLogo } from './NavbarElements.js'
import { animateScroll as scroll } from 'react-scroll'

const Navbar = ({toggle, sectionsInView}) => {
    const [scrollnav, setscrollnav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setscrollnav(true);
        }
        else {
            setscrollnav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <Nav scrollnav={scrollnav}>
            <NavbarContainer>
                <AnvinLogo scrollnav={scrollnav} to='/' onClick={toggleHome}>
                    <LogoUpperPart scrollnav={scrollnav}/>
                    <LogoLowerPart/>
                    <LogoText scrollnav={scrollnav} to='/' 
                        style={{textAlign: "left", marginTop: "2em"}}>
                        Anna{"\n"}Vinokurova
                    </LogoText>
                </AnvinLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu className="NavMenu">
                    <NavItem className="NavItem">
                        <NavLinks to='about' className="NavLinks"
                            smooth={true} duration={500} spy={true}
                            exact={"true"} offset={-80}
                            >About</NavLinks>
                            <NavSVGContainer scrollnav={sectionsInView[1]}>
                                <NavSVG 
                                    width="100%" height="100%" viewBox="0 0 104 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M59.3075 9.16619C58.5149 6.36159 56.0479 5.04146 53.4776 4.00429C47.3226 1.5207 40.1102 1.3975 33.5739 1.13488C28.545 0.932825 23.5128 0.983226 18.4829 1.1197C14.9424 1.21576 11.2907 1.19943 7.80992 1.95471C4.96826 2.5713 4.65205 3.35655 4.65205 5.94759C4.65205 8.92232 4.89333 11.7431 6.71681 14.237C11.1006 20.2325 19.6773 22.5761 26.5598 23.9991C36.7957 26.1153 48.1274 26.6414 58.4725 24.9252C61.6612 24.3962 69.8742 23.1007 69.8742 18.4272C69.8742 13.7697 61.8993 11.2264 58.5787 10.1378C50.8215 7.59472 42.655 6.34027 34.6214 5.03667C29.4424 4.19626 24.3597 3.43734 19.1054 3.42737C14.2742 3.4182 8.8486 3.13625 4.16622 4.58121C2.69515 5.03517 1.13573 6.05494 1.00836 7.73907C0.875788 9.49191 2.34876 11.1829 3.43748 12.3544C5.66263 14.7489 8.33595 16.5587 11.3018 17.9111C20.4404 22.078 31.094 23.1283 40.9372 24.3179C49.8023 25.3893 58.7481 26.2692 67.688 25.8057C72.3966 25.5616 78.1183 25.3144 82.4449 23.1337C85.4484 21.6198 87.081 18.4121 83.8417 16.3321C82.5185 15.4825 81.0415 15.3672 79.53 15.1783" stroke="#EF6161" strokeLinecap="round"/>
                                </NavSVG>
                            </NavSVGContainer>
                    </NavItem>
                    <NavItem className="NavItem">
                        <NavLinks to='projects' className="NavLinks"
                            smooth={true} duration={500} spy={true}
                            exact={"true"} offset={-80}
                        >Projects</NavLinks>
                        <NavSVGContainer scrollnav={sectionsInView[2]}>
                            <NavSVG 
                                width="100%" height="100%" viewBox="0 0 104 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M59.3075 9.16619C58.5149 6.36159 56.0479 5.04146 53.4776 4.00429C47.3226 1.5207 40.1102 1.3975 33.5739 1.13488C28.545 0.932825 23.5128 0.983226 18.4829 1.1197C14.9424 1.21576 11.2907 1.19943 7.80992 1.95471C4.96826 2.5713 4.65205 3.35655 4.65205 5.94759C4.65205 8.92232 4.89333 11.7431 6.71681 14.237C11.1006 20.2325 19.6773 22.5761 26.5598 23.9991C36.7957 26.1153 48.1274 26.6414 58.4725 24.9252C61.6612 24.3962 69.8742 23.1007 69.8742 18.4272C69.8742 13.7697 61.8993 11.2264 58.5787 10.1378C50.8215 7.59472 42.655 6.34027 34.6214 5.03667C29.4424 4.19626 24.3597 3.43734 19.1054 3.42737C14.2742 3.4182 8.8486 3.13625 4.16622 4.58121C2.69515 5.03517 1.13573 6.05494 1.00836 7.73907C0.875788 9.49191 2.34876 11.1829 3.43748 12.3544C5.66263 14.7489 8.33595 16.5587 11.3018 17.9111C20.4404 22.078 31.094 23.1283 40.9372 24.3179C49.8023 25.3893 58.7481 26.2692 67.688 25.8057C72.3966 25.5616 78.1183 25.3144 82.4449 23.1337C85.4484 21.6198 87.081 18.4121 83.8417 16.3321C82.5185 15.4825 81.0415 15.3672 79.53 15.1783" stroke="#EF6161" strokeLinecap="round"/>
                            </NavSVG>
                        </NavSVGContainer>
                    </NavItem>
                    <NavItem className="NavItem">
                        <NavLinks to='creative' className="NavLinks"
                            smooth={true} duration={500} spy={true}
                            exact={"true"} offset={-80}
                        >Creative</NavLinks>
                        <NavSVGContainer scrollnav={sectionsInView[3]}>
                            <NavSVG 
                                width="100%" height="100%" viewBox="0 0 104 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M59.3075 9.16619C58.5149 6.36159 56.0479 5.04146 53.4776 4.00429C47.3226 1.5207 40.1102 1.3975 33.5739 1.13488C28.545 0.932825 23.5128 0.983226 18.4829 1.1197C14.9424 1.21576 11.2907 1.19943 7.80992 1.95471C4.96826 2.5713 4.65205 3.35655 4.65205 5.94759C4.65205 8.92232 4.89333 11.7431 6.71681 14.237C11.1006 20.2325 19.6773 22.5761 26.5598 23.9991C36.7957 26.1153 48.1274 26.6414 58.4725 24.9252C61.6612 24.3962 69.8742 23.1007 69.8742 18.4272C69.8742 13.7697 61.8993 11.2264 58.5787 10.1378C50.8215 7.59472 42.655 6.34027 34.6214 5.03667C29.4424 4.19626 24.3597 3.43734 19.1054 3.42737C14.2742 3.4182 8.8486 3.13625 4.16622 4.58121C2.69515 5.03517 1.13573 6.05494 1.00836 7.73907C0.875788 9.49191 2.34876 11.1829 3.43748 12.3544C5.66263 14.7489 8.33595 16.5587 11.3018 17.9111C20.4404 22.078 31.094 23.1283 40.9372 24.3179C49.8023 25.3893 58.7481 26.2692 67.688 25.8057C72.3966 25.5616 78.1183 25.3144 82.4449 23.1337C85.4484 21.6198 87.081 18.4121 83.8417 16.3321C82.5185 15.4825 81.0415 15.3672 79.53 15.1783" stroke="#EF6161" strokeLinecap="round"/>
                            </NavSVG>
                        </NavSVGContainer>
                    </NavItem>
                    <NavItem className="NavItem">
                        <NavLinks to='contact' className="NavLinks"
                            smooth={true} duration={500} spy={true}
                            exact={"true"} offset={-80}
                        >Contact</NavLinks>
                        <NavSVGContainer scrollnav={sectionsInView[4]}>
                            <NavSVG 
                                width="100%" height="100%" viewBox="0 0 104 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M59.3075 9.16619C58.5149 6.36159 56.0479 5.04146 53.4776 4.00429C47.3226 1.5207 40.1102 1.3975 33.5739 1.13488C28.545 0.932825 23.5128 0.983226 18.4829 1.1197C14.9424 1.21576 11.2907 1.19943 7.80992 1.95471C4.96826 2.5713 4.65205 3.35655 4.65205 5.94759C4.65205 8.92232 4.89333 11.7431 6.71681 14.237C11.1006 20.2325 19.6773 22.5761 26.5598 23.9991C36.7957 26.1153 48.1274 26.6414 58.4725 24.9252C61.6612 24.3962 69.8742 23.1007 69.8742 18.4272C69.8742 13.7697 61.8993 11.2264 58.5787 10.1378C50.8215 7.59472 42.655 6.34027 34.6214 5.03667C29.4424 4.19626 24.3597 3.43734 19.1054 3.42737C14.2742 3.4182 8.8486 3.13625 4.16622 4.58121C2.69515 5.03517 1.13573 6.05494 1.00836 7.73907C0.875788 9.49191 2.34876 11.1829 3.43748 12.3544C5.66263 14.7489 8.33595 16.5587 11.3018 17.9111C20.4404 22.078 31.094 23.1283 40.9372 24.3179C49.8023 25.3893 58.7481 26.2692 67.688 25.8057C72.3966 25.5616 78.1183 25.3144 82.4449 23.1337C85.4484 21.6198 87.081 18.4121 83.8417 16.3321C82.5185 15.4825 81.0415 15.3672 79.53 15.1783" stroke="#EF6161" strokeLinecap="round"/>
                            </NavSVG>
                        </NavSVGContainer>
                    </NavItem>
                </NavMenu>
                {/* <NavButton>
                    <NavButtonLink to="/signing">Sign in</NavButtonLink>
                </NavButton> */}
            </NavbarContainer>
        </Nav>
    )
}

export default Navbar

import './App.css';
import Home from './pages/Home.js';
import { BrowserRouter as Router } from 'react-router-dom'
import { Route, Routes} from 'react-router-dom'
// import { Route } from 'react-router'
import {Helmet} from "react-helmet";
import Photography from './components/Photography/Photography';



function App() {
  return (
    <>

    <Router>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#6f6f6f"/>
        <meta name="msapplication-TileColor" content="#fbf7f3"/>
        <meta name="theme-color" content="#fbf7f3"></meta>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
      </Helmet>
      <Routes>
        <Route exact path='/' element={<Home/>} /> 
        <Route exact path='/photography' element={<Photography/>} /> 
      </Routes>
    </Router>
    </>
  );
}

export default App;

import styled from 'styled-components'
import { Link as LinkScroll } from 'react-scroll'
import redArrow from '../../images/REDarrowABOUT.svg';
import closeIcon from '../../images/close.svg';

export const BlurredBackground = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    z-index: 90;
    background-color: #FBF7F380;
    backdrop-filter: blur(8px);
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    top: 0;
    min-height: 100vh;
`
export const PasswordContainer = styled.div`
    position: fixed;
    top: 25em;
    width: 80em;
    height: 60em;
    background-color: #151C1E;
    border-radius: 5em;
    color: black;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
    z-index: 100;
    text-align: left;
`

export const PasswordInputField = styled.input`
    background-color: #FBF7F3;
    font-size: 1.2em;
    border-radius: 5em;
    border: none;
    width: 20em;
    height: 6em;
    text-align: center;
`

export const SubmitButton = styled.button`
    background-color: #FBF7F350;
    border-radius: 5em;
    border: none;
    width: 20em;
    height: 6em;
    text-align: center;

    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    /* overflow: hidden; */
    outline: none;

`

export const RedArrow = styled.div`
    height: 6em;
    width: 6em;
    margin-left: 8em;
    margin-top: -5em;
    background-image: url(${ redArrow });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
`

export const CloseIcon = styled.div`
    position: absolute;
    height: 6em;
    width: 6em;
    margin-left: 0em;
    margin-top: 4em;
    background-image: url(${ closeIcon });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
`

import styled from 'styled-components'
import star from '../images/STAR.svg';
import sun from '../images/sun.svg';
import line from '../images/line.svg';
import mail from '../images/emojiMAIL.svg';

export const PortfolioContainer = styled.div`
    background: #FBF7F3;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    /* height: min(calc(100vh - 80px), 2000px); */
    /* height: min(calc(100vh - 10em), 3000px); */
    /* height: min(calc(100vh - 10em), auto); */
    /* height: min(calc(100vh - 10em), auto); */
    /* height: min(calc(100vh - 10em), 2000px); */
    /* max-height: min(calc(100vh - 10em), 4000px); */
    
    /* min-height: calc(100vh - 10em); */

    /* min-height: max(auto, 3000px); */

    height: min(calc(100vh - 10em), 2000px);
    height: ${({ extended }) => (extended ? 'auto' : 'min(calc(100vh - 10em), 2000px)')};

    position: relative;
    z-index: 1;
`

export const PortfolioTitle = styled.div`
    display: flex;
    align-items: left;
    flex-direction: column;
    padding-bottom: 20vh;
    text-align: left;
`

export const PortfolioContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
    z-index: 3;
    text-align: left;
`

export const PortfolioText = styled.div`
    display: flex;
    align-items: left;
    flex-direction: column;
    padding-bottom: 14em;
    text-align: left;
`

export const PortfolioH1 = styled.h1`
    color: #151C1E;
    min-width: min(1200px, 80vmin);
    font-size: 5.2em;
    text-align: left;
    font-family: LeAmatchky;
    font-style: normal;
    font-weight: normal;
    line-height: 0.7em;
    white-space: pre;
`

export const PortfolioH2 = styled.h2`
    color: #151C1E;
    font-size: 3em;
    text-align: left;
    font-family: LeAmatchky;
    font-style: normal;
    font-weight: normal;
    white-space: pre;
`

export const PortfolioP1 = styled.p`
    color: #151C1E;
    font-family: Montserrat;
    font-size: 2.8em;
    font-weight: 400;
    text-align: left;
    white-space: pre-wrap;
`

export const PortfolioP2 = styled.p`
    color: #151C1E;
    font-family: Montserrat;
    font-size: 1.7em;
    font-weight: 400;
    text-align: center;
    max-width: 600px;
    white-space: pre-wrap;
`

export const PortfolioP3 = styled.p`
    color: #151C1E;
    font-family: Montserrat;
    font-size: 1.3em;
    font-weight: 400;
    text-align: center;
    white-space: pre-wrap;
`

export const FlexRows = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: flex-start;
`

export const FlexColumns = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
`

export const StarSVG = styled.div`
    height: 10em;
    width: 10em;
    /* padding-top: 3em; */
    background-image: url(${ star });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

export const SunSVG = styled.div`
    height: 6em;
    width: 6em;
    /* padding-top: 3em; */
    background-image: url(${ sun });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

export const MailSVG = styled.div`
    height: 9em;
    width: 9em;
    /* padding-top: 3em; */
    background-image: url(${ mail });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

export const Line = styled.div`
    width: 100em;
    height: 1em;
    padding-top: 3em;
    background-image: url(${ line });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'
import logoUp from '../../images/LogoUp.svg';
import logoDown from '../../images/LogoDown.svg';

export const Nav = styled.nav`
    background-image: linear-gradient(to bottom, 
        #FBF7F3 0%, 
        #FBF7F3D0 60%, 
        #FBF7F3A0 70%, 
        #FBF7F380 80%, 
        #FBF7F340 90%, 
        #FBF7F300 95%, 
        #FBF7F300 100%);
    width: 100vw;
    height: 10em;
    /* margin-top: -80px; */
    display: flex;
    justify-content: center;    
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    backdrop-filter: blur(1px);
/* 
    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    } */
`

export const BlurryDiv = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    filter: blur(2em);
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 10em;
    /* width: 100%; */
    width: 100em;
    z-index: 1;
`

export const LogoText = styled(LinkRouter) `
    display: flex;
    color: black;
    font-size: 1.3em;
    margin-left: 3em;
    padding-top: 0.3em;
    justify-self: flex-start;
    cursor: pointer;
    align-items: center;
    white-space: pre-wrap;
    line-height: 1.6em;

    text-decoration: none;

    font-family: Montserrat;

    opacity: ${({ scrollnav }) => (scrollnav ? '0' : '1')};
    transition: opacity 0.2s ease-in-out;
`

export const AnvinLogo = styled(LinkRouter)`
    /* position: absolute; */
    position: relative;
    height: 3em;
    width: 3em;
    /* left: 10em; */
    text-decoration: none;
`

export const LogoUpperPart = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 3em;
    left: ${({ scrollnav }) => (scrollnav ? '0.4em' : '0em')};
    background-image: url(${ logoUp });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    transition: left 0.2s ease-in-out;
`

export const LogoLowerPart = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 10em;
    /* top: 100%; */
    /* padding-top: 310%; */
    background-image: url(${ logoDown });
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 22px;
        right: 22px;
        transform: translate (-100%, 60%);
        /* font-size: 1.8rem; */
        font-size: 1.8em;
        cursor: pointer;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavItem = styled.li`
    display: list-item;
    height: 80px;
`

export const NavLinks = styled(LinkScroll)`
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-left: 3em;
    height: 100%;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 1.3em;
`

export const NavButton = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavButtonLink = styled(LinkRouter)`
    border-radius: 50px;
    background: white;
    white-space: nowrap;
    padding: 10px, 22px;
    color: black;
    /* font-size: 16px; */
    /* font-size: 1em; */
    /* font-size: 2em; */
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    align-items: center;

    &.hover {
        transition: all 0.2s ease-in-out;
        background: red;
        color: blue;
    }

`

export const NavSVGContainer = styled.div`
    position: absolute;
    margin-left: 1em;
    top: 2.8em;
    /* margin-top: -6.4em; */
    width: 14em;
    pointer-events: none;
    
    opacity: ${({ scrollnav }) => (scrollnav ? '1' : '0')};
    
    /* max-height: 0vh; */
    transition: all 0.2s ease-in-out;

    /* &.active {
        opacity: 1;
    } */
`

export const NavSVG = styled.svg`
    stroke-dasharray: 1200;
    stroke-dashoffset: 0;
    /* animation: dash 10s linear infinite; */
    pointer-events: none;

    @keyframes dash {
        0% {
            stroke-dashoffset: 1200;
        }
        5% {
            stroke-dashoffset: 1200;
        }
        15% {
            stroke-dashoffset: 0;
        }
        90% {
            stroke-dashoffset: 0;
        }
        100% {
            stroke-dashoffset: -1200;
        }
    }
`

import React, {useState, useEffect} from 'react'
import { PortfolioP3 } from '../PortfolioElements'
import { ContactButtonContainer, Star } from './ContactButtonElements'

function ContactButton(props) {
    const [hover, setHover] = useState(false);
    
    return (
        <>
            <ContactButtonContainer className='Text' onClick={()=> window.open(props.link)} 
                onPointerOver={()=> setHover(true)}
                onPointerOut={() => setHover(false)}>
                <Star style={{marginLeft: "1em"}}/>
                <PortfolioP3 style={{textAlign: "center", marginLeft: "-0em", marginTop: "0.4em",
                    color: hover ? '#FBF7F3': '#151C1E',
                    transition: "all 0.3s ease-in-out"
                    }}>
                    {props.text}
                </PortfolioP3>
            </ContactButtonContainer>
        </>
        
    )
}

export default ContactButton
